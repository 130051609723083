import { Component, HostBinding, Input, OnInit, inject } from '@angular/core';
import { ChatBackendBase as BantaBackend } from '@banta/sdk';
import { LoggerService } from '@tytapp/common';
import { isServerSide } from '@tytapp/environment-utils';

interface CountQueueItem {
    id: string;
    callback: (count: number) => void;
}

let COUNT_QUEUE: CountQueueItem[] = [];
let COUNT_UPDATE_TIMEOUT;

@Component({
    selector: 'tyt-comments-count',
    templateUrl: './comments-count.component.html',
    styleUrls: ['./comments-count.component.scss']
})
export class CommentsCountComponent implements OnInit {
    private banta = inject(BantaBackend);
    private logger = inject(LoggerService);

    _identifier: string = null;

    @Input()
    get identifier(): string {
        return this._identifier;
    }

    set identifier(value: string) {
      if (this._identifier == value)
        return;

        this._identifier = value;
        this.update();
    }

    ngOnInit() {
    }

    count: number;

    updateCounts() {
        if (isServerSide())
          return;

        clearTimeout(COUNT_UPDATE_TIMEOUT);
        COUNT_UPDATE_TIMEOUT = setTimeout(async () => {
            if (COUNT_QUEUE.length == 0) {
                return;
            }

            const items = COUNT_QUEUE.splice(0, 30);
            let count = 0;

            let counts = await this.banta.getSourceCountForTopics(items.map(x => x.id));
            for (let i = 0; i < items.length ; i++) {
                try {
                    count = counts[items[i].id] ?? 0;
                    items[i].callback(count);
                } catch (e) {
                    this.logger.error(`Failed to fetch comment count for topic '${items[i].id}_comments': ${e.message}`);
                    items[i].callback(0);
                }
            }

            if (COUNT_QUEUE.length > 0) {
                this.updateCounts();
            }
        }, 1000);
    }

    @HostBinding('class.visible')
    get hasCount() {
        return this.count !== void 0;
    }

    async update() {
        if (!this._identifier) {
            return;
        }

        // Never save to COUNT_QUEUE when in SSR, because we are never
        // emptying the queue. Memory leak found mid August 2021

        if (isServerSide()) {
            return;
        }

        let enabled = true;
        if (!enabled) {
            this.count = 0;
            return;
        }

        COUNT_QUEUE.push({
            id: `${this._identifier}_comments`,
            callback: count => this.count = count
        });

        this.updateCounts();
    }
}
